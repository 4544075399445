import React from "react"

const activeItem = (inc, page) => {
    if (inc === page) {
        return "page-item active"
    } else {
        return "page-item"
    }
}

const MiddleLinks = ({ page, perPage, total, updateFunc }) => {
    const totalPages = Math.floor((total - 1) / perPage) + 1
    const displayLinks = 7
    const midLink = Math.ceil(displayLinks / 2)

    let startPage
    let endPage
    let pageLinks = []

    if (page - midLink < 1) {
        if (totalPages < displayLinks) {
            startPage = 0
            endPage = totalPages
        } else {
            startPage = 0
            endPage = displayLinks
        }
    }

    if (page - midLink >= 1) {
        if (page + midLink > totalPages) {
            startPage = totalPages - displayLinks
            endPage = totalPages
        } else {
            startPage = page - midLink
            endPage = page + midLink - 1
        }
    }

    for (let i = startPage; i < endPage; i++) {
        pageLinks.push(
            <li className={activeItem(i + 1, page)} key={i}>
                <button
                    type="button"
                    className="page-link"
                    onClick={() => updateFunc(i + 1, perPage)}
                >
                    {i + 1}
                </button>
            </li>
        )
    }

    return pageLinks
}

export default MiddleLinks
