import React from "react"

const LastLink = ({ perPage, total, updateFunc }) => {
    const totalPages = Math.floor((total - 1) / perPage) + 1

    return (
        <li className="page-item">
            <button
                type="button"
                className="page-link"
                aria-label="Last"
                onClick={() => updateFunc(totalPages, perPage)}
            >
                <span aria-hidden="true">Last</span>
            </button>
        </li>
    )
}

export default LastLink
