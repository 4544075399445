import React from "react"

import FirstLink from "../Pagination/firstLink"
import MiddleLinks from "../Pagination/middleLinks"
import LastLink from "../Pagination/lastLink"

import "./styles.scss"

const Pagination = ({ page, perPage, total, updateFunc }) => {
    if (total <= perPage) {
        return null
    } else {
        return (
            <div className="col-12">
                <ul className="pagination justify-content-center">
                    <FirstLink perPage={perPage} updateFunc={updateFunc} />
                    <MiddleLinks
                        page={page}
                        perPage={perPage}
                        total={total}
                        updateFunc={updateFunc}
                    />
                    <LastLink
                        perPage={perPage}
                        total={total}
                        updateFunc={updateFunc}
                    />
                </ul>
            </div>
        )
    }
}

export default Pagination
