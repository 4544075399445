import React from "react"
import axios from "axios"

import Loader from "../Loader"
import Message from "../Message"
import Pagination from "../Pagination"

import BoxLayout from "../Box"
import BoxTitle from "../Box/title"
import BoxDate from "../Box/date"
import BoxDescription from "../Box/description"
import ArticleLink from "../Box/Links/article"

const newsAPI = process.env.API_NEWS

class NewsList extends React.Component {
    constructor(props) {
        super(props)
        this.newsList = React.createRef()
        this.state = {
            news: [],
            page: 1,
            perPage: 20,
            isLoading: true,
            message: undefined,
        }
    }

    componentDidMount = () => {
        const { page, perPage } = this.state
        this.getNews(page, perPage)
    }

    getNews = (page, perPage) => {
        axios
            .get(`${newsAPI}?page=${page}&per-page=${perPage}`)
            .then((response) => {
                this.setState({
                    news: response.data.news,
                    total: response.data.total,
                    page: page,
                    isLoading: false,
                })
            })
            .catch((error) => {
                this.setState({
                    message: {
                        type: "danger",
                        text: error.response.data.message,
                    },
                    isLoading: false,
                })
            })
    }

    updateNewsList = (page, perPage) => {
        window.scrollTo(0, this.newsList.current.offsetTop - 120)
        this.getNews(page, perPage)
    }

    render() {
        const { isLoading, message, news, page, perPage, total } = this.state
        let allNews = news.map((article) => (
            <BoxLayout key={article.id}>
                <BoxTitle name={article.title} />
                <BoxDate date={article.date} />
                <BoxDescription description={article.description} />
                <ArticleLink id={article.id} icon="chevron-right">
                    Read More
                </ArticleLink>
            </BoxLayout>
        ))
        if (isLoading === true) {
            return <Loader />
        } else if (message) {
            return <Message type={message.type} message={message.text} />
        } else {
            return (
                <>
                    <div className="row" ref={this.newsList}>
                        {allNews}
                    </div>
                    <div className="row">
                        <Pagination
                            page={page}
                            perPage={perPage}
                            total={total}
                            updateFunc={this.updateNewsList}
                        />
                    </div>
                </>
            )
        }
    }
}

export default NewsList
