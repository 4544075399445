import React from "react"

import NewsList from "../components/News/list"
import Layout from "../components/layout"
import Page from "../components/Page/page"

class Index extends React.Component {
  render() {
    const { location } = this.props
    return (
      <Layout location={location}>
        <Page id="8" />
        <NewsList />
      </Layout>
    )
  }
}

export default Index
