import React from "react"

import "./styles.scss"

const BoxLayout = ({children}) => (
    <div className="col-xl-3 col-sm-6">
        <div className="box">
           {children}
        </div>
    </div>
)

export default BoxLayout