import React from "react"

const FirstLink = ({ perPage, updateFunc }) => (
    <li className="page-item">
        <button
            type="button"
            className="page-link"
            aria-label="Previous"
            onClick={() => updateFunc(1, perPage)}
        >
            <span aria-hidden="true">First</span>
        </button>
    </li>
)

export default FirstLink
