import React from "react"
import Icon from "../../Icon"
import {Link} from "gatsby"

const NewsLink = ({id, icon, children}) => {
    if(id !== "") {
        return(
            <nav className="nav flex-column text-left">
                <div className="link-container">
                    <Icon prefix="fas" name={icon} />
                    <Link  to={`/article/${id}`}>
                        {children}
                    </Link>
                </div>
            </nav>
        )
    } else {
        return(null)
    }
}

export default NewsLink